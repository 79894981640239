import React from "react";

//** Load App
const LazyApp = React.lazy(() => import("./App"));

/*Hooks*/
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SlideInModal from "./views/SlideInModal";
import SlideInModalV2 from "@components/SlideInModal";

/*Redux*/
import { getLanguage } from "@store/common/selectors";

const InitApp = ({ DefaultRoute, listRoutes, listNav }) => {
  const { i18n } = useTranslation();

  /*Selectors*/
  const lang = useSelector((state) => getLanguage(state));

  React.useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <React.Fragment>
      <SlideInModal />
      <SlideInModalV2 />
      <LazyApp DefaultRoute={DefaultRoute} Routes={listRoutes} Nav={listNav} />
    </React.Fragment>
  );
};
export default InitApp;
