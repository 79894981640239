import { handleActions } from "redux-actions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { generateFormSubmitRedux } from "@utility/StoreMethod";

const nameSpace = "randomExamsUser";

const reducer = generateFormSubmitRedux(nameSpace);

export const actions = reducer.actions;
export const types = reducer.types;
export const defaultState = reducer.defaultState;

const reducerMain = handleActions(reducer?.handleActions, defaultState);

const persistConfig = {
  key: nameSpace,
  storage,
  blacklist: ["loading", "error"],
};

export default persistReducer(persistConfig, reducerMain);
