import {
  ENV_ENVIRONMENT_BASE_URL_API,
  ENV_ENVIRONMENT_BASE_URL_API_DEV,
} from "./environment";
export const BASE_URL_API = ENV_ENVIRONMENT_BASE_URL_API;
export const BASE_URL_API_DEV = ENV_ENVIRONMENT_BASE_URL_API_DEV;

export default {
  /*Basic*/
  Province: "/Province",
  District: "/District",
  SchoolClass: "/SchoolClass",

  /*Common*/
  GET_COMMON_GET_CITY: "/MProvince/list-province",
  GET_COMMON_GET_DISTRICT: "/District",
  GET_LIST_CLASS: "/SchoolClass",
  GET_SHARE_INFO: "WebsiteSTNHD/ShareInfo",
  GET_LIST_OPTIONS_SETUP: "/Options/list/groupcode/otp_setup",
  POST_SEND_OTP_EMAIL: "/AccountHelperEmail/SendOTPEmail",
  GET_ACCOUNT_PROFILE: "/User/GetProfile",
  POST_CHECK_ACTIVE_CLASS_CODE: "/SieuTriNhoHocDuong/CheckCode5PTB",
  UPDATE_USER_INFO: "/User/update",
  CHECK_IS_UPDATE_INFO: "/User/check-update-bankInfo",

  //
  API_DETAIL: "/MstDialing/detail-dialing",
  API_LIST_USER_DRAW: "/InfoDialingUser/get-list-dialing-user-by-dialing-id",
};
