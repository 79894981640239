import API from "../../configs/api";
import apiMethodTHVV from "@utility/ApiMethod";

export const listClassDetailCallMethod = ({
  codeLanguage = "vi-VN",
  payload,
}) => {
  const { id } = payload;

  return apiMethodTHVV.get(`${API.API_GET_DETAIL_LIST_CLASS}/${id}`, payload);
};
