import { APP_ID } from "@src/configs/contants";
import API from "../../configs/api";
import apiMethod from "../../utility/ApiMethod";
import apiMethodDev from "../../utility/ApiMethodDev";

export const getProvince = () => {
  return apiMethod.get(API.Province);
};

export const getListProvince = (payload) => {
  const { culture = "vi-VN", page, limit, ...res } = payload;

  return apiMethod.get(`${culture}${API.GET_COMMON_GET_CITY}`, {
    params: {
      page,
      limit,
      ...res,
    },
  });
};

export const getDistrict = ({ ProvinceId }) => {
  return apiMethod.get(API.District, {
    params: {
      ProvinceId,
    },
  });
};

export const getSchoolClass = () => {
  return apiMethod.get(API.SchoolClass);
};

export const apiGetIsUpdateBankInfo = async (payload) => {
  const { codeLanguage = "vi-VN", ...rest } = payload;
  try {
    const { data } = await apiMethod.get(
      `${codeLanguage}${API.CHECK_IS_UPDATE_INFO}`,
      { params: { userId: payload?.userId } }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getOneExams = async (id) => {
  try {
    const { data } = await apiMethod.get(`${API.API_DETAIL}?dialing=${id}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const listUserDraw = async (payload) => {
  try {
    const { page, limit, dialingId, userId } = payload;
    const { data } = await apiMethod.post(
      `${API.API_LIST_USER_DRAW}`,
      payload,
      { params: { page, limit } },
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};