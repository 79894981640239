import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";
import initSagas from "./Store/initSagas";

export default {
  name: "Home",
  dir: "Home",
  pathRoot: "",
  routes: [
    {
      url: "",
      component: "Page/HomeV2",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh sách | Hita Camp",
        titleI18n: "Home:title",
        headerStyle: "fill",
        exact: true,
      },
    },
    {
      url: "home-v1",
      component: "Page/Homepage",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh sách | Hita Camp",
        titleI18n: "Home:title",
        headerStyle: "fill",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  sagas: initSagas,
};
