import API from "../../configs/api";
import apiMethodTHVV from "@utility/ApiMethod";

export const listDialingClientCallMethod = ({
  codeLanguage = "vi-VN",
  payload,
}) => {
  const { params, ...rest } = payload;

  return apiMethodTHVV.post(API.GET_LIST_EXAMS, rest, { params });
};
