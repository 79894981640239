import { takeLatest } from "redux-saga/effects";
import { actions, types } from "./reducer";
import * as service from "./service";
import * as StoreMethod from "@utility/StoreMethod";

const nameSpace = "listDialingUser";
const nameUp = "listDialingUser".toUpperCase();

const generateFormSubmitSagas = StoreMethod.generateFormSubmitSagas(
  nameSpace,
  actions,
  service,
);

export default function* () {
  yield takeLatest(
    types?.[`${nameUp}_METHOD`],
    generateFormSubmitSagas?.[`${nameSpace}CallMethod`],
  );
}
