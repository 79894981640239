import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";
import initSagas from "./Store/initSagas";

export default {
  name: "SanChoi",
  dir: "SanChoi",
  pathRoot: "sanchoi",
  routes: [
    {
      url: "",
      component: "Page/index",
      layout: "SanChoiLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh sách | Hita Camp",
        titleI18n: "SanChoi:title",
        headerStyle: "fill",
        exact: true,
      },
    },
    {
      url: "list-class",
      component: "Page/ListClass",
      layout: "SanChoiLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh sách | Hita Camp",
        titleI18n: "SanChoi:title",
        headerStyle: "fill",
        exact: true,
      },
    },
    {
      url: "list-class-multiple-lesson",
      component: "Page/ListClassMultipleLesson",
      layout: "SanChoiLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh sách | Hita Camp",
        titleI18n: "SanChoi:title",
        headerStyle: "fill",
        exact: true,
      },
    },
    {
      url: "rank",
      component: "Page/Ranking",
      layout: "SanChoiLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh sách | Hita Camp",
        titleI18n: "SanChoi:title",
        headerStyle: "fill",
        exact: true,
      },
    },
    {
      url: "countdown",
      component: "Page/CountDown",
      layout: "SanChoiLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh sách | Hita Camp",
        titleI18n: "SanChoi:title",
        headerStyle: "fill",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  sagas: initSagas,
};
