import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";
import initSagas from "./Store/initSagas";

export default {
  name: "LuckyDraw",
  dir: "LuckyDraw",
  pathRoot: "lucky-draw",
  routes: [
    {
      url: "list-prize/:id",
      component: "Page/ListWinPrizeV2/index",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh sách | Hita Camp",
        titleI18n: "LuckyDraw:title_list",
        headerStyle: "fill",
        exact: true,
      },
    },
    {
      url: ":id",
      component: "Page/LuckyDrawPage",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh sách | Hita Camp",
        titleI18n: "LuckyDraw:title",
        headerStyle: "fill",
        exact: true,
      },
    },
    {
      url: "lucky-draw-san-choi/:id",
      component: "Page/LuckyDrawSanchoiPage",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Danh sách | Hita Camp",
        titleI18n: "LuckyDraw:title",
        headerStyle: "fill",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  sagas: initSagas,
};
